import request from '@/utils/request'

// 查询充值菜单列表
export function listUserRechargeMenu(query) {
  return request({
    url: '/platform/user-recharge-menu/list',
    method: 'get',
    params: query
  })
}

// 查询充值菜单分页
export function pageUserRechargeMenu(query) {
  return request({
    url: '/platform/user-recharge-menu/page',
    method: 'get',
    params: query
  })
}

// 查询充值菜单详细
export function getUserRechargeMenu(data) {
  return request({
    url: '/platform/user-recharge-menu/detail',
    method: 'get',
    params: data
  })
}

// 新增充值菜单
export function addUserRechargeMenu(data) {
  return request({
    url: '/platform/user-recharge-menu/add',
    method: 'post',
    data: data
  })
}

// 修改充值菜单
export function updateUserRechargeMenu(data) {
  return request({
    url: '/platform/user-recharge-menu/edit',
    method: 'post',
    data: data
  })
}

// 删除充值菜单
export function delUserRechargeMenu(data) {
  return request({
    url: '/platform/user-recharge-menu/delete',
    method: 'post',
    data: data
  })
}
